/*
if (!Turbolinks) {
location.reload();
}

Turbolinks.dispatch("turbolinks:load");
*/

(function ($) {

    'use strict';

    function initMetisMenu() {
        //metis menu
        $("#side-menu").metisMenu();
    }

    function initLeftMenuCollapse() {
        $('#vertical-menu-btn').on('click', function (event) {
            event.preventDefault();
            $('body').toggleClass('sidebar-enable');
            if ($(window).width() >= 992) {
                $('body').toggleClass('vertical-collpsed');
            } else {
                $('body').removeClass('vertical-collpsed');
            }
        });
    }

    function initActiveMenu() {
        // === following js will activate the menu in left side bar based on url ====
        $("#sidebar-menu a").each(function () {
            var pageUrl = window.location.href.split(/[?#]/)[0];
            if (this.href == pageUrl) {
                $(this).addClass("active");
                $(this).parent().addClass("mm-active"); // add active to li of the current link
                $(this).parent().parent().addClass("mm-show");
                $(this).parent().parent().prev().addClass("mm-active"); // add active class to an anchor
                $(this).parent().parent().parent().addClass("mm-active");
                $(this).parent().parent().parent().parent().addClass("mm-show"); // add active to li of the current link
                $(this).parent().parent().parent().parent().parent().addClass("mm-active");
            }
        });
    }

    function initMenuItem() {
        $(".navbar-nav a").each(function () {
            var pageUrl = window.location.href.split(/[?#]/)[0];
            if (this.href == pageUrl) { 
                $(this).addClass("active");
                $(this).parent().addClass("active");
                $(this).parent().parent().addClass("active");
                $(this).parent().parent().parent().addClass("active");
                $(this).parent().parent().parent().parent().addClass("active");
                $(this).parent().parent().parent().parent().parent().addClass("active");
            }
        });
    }

    function initFullScreen() {
        $('[data-toggle="fullscreen"]').on("click", function (e) {
            e.preventDefault();
            $('body').toggleClass('fullscreen-enable');
            if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        });
        document.addEventListener('fullscreenchange', exitHandler );
        document.addEventListener("webkitfullscreenchange", exitHandler);
        document.addEventListener("mozfullscreenchange", exitHandler);
        function exitHandler() {
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                console.log('pressed');
                $('body').removeClass('fullscreen-enable');
            }
        }
    }

    function initRightSidebar() {
        // right side-bar toggle
        $('.right-bar-toggle').on('click', function (e) {
            $('body').toggleClass('right-bar-enabled');
        });

        $(document).on('click', 'body', function (e) {
            if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
                return;
            }

            $('body').removeClass('right-bar-enabled');
            return;
        });
    }

    function initDropdownMenu() {
        $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
            if (!$(this).next().hasClass('show')) {
              $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
            }
            var $subMenu = $(this).next(".dropdown-menu");
            $subMenu.toggleClass('show');
    
            return false;
        });   
    }
    
    function initComponents() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })

        // $(function () {
        //     $('[data-toggle="popover"]').popover()
        // })
    }

    function initPreloader() {
        $(window).on('load', function() {
            $('#status').fadeOut();
            $('#preloader').delay(350).fadeOut('slow');
        });
    }

    function initSettings() {
        if (window.sessionStorage) {
            var alreadyVisited = sessionStorage.getItem("is_visited");
            if (!alreadyVisited) {
                sessionStorage.setItem("is_visited", "light-mode-switch");
            } else {
                $(".right-bar input:checkbox").prop('checked', false);
                $("#"+alreadyVisited).prop('checked', true);
                updateThemeSetting(alreadyVisited);
            }
        }
        $("#light-mode-switch, #dark-mode-switch, #rtl-mode-switch").on("change", function(e) {
            updateThemeSetting(e.target.id);
        });
    }

    function updateThemeSetting(id) {
        if($("#light-mode-switch").prop("checked") == true && id === "light-mode-switch"){
            $("#dark-mode-switch").prop("checked", false);
            $("#rtl-mode-switch").prop("checked", false);
            $("#bootstrap-style").attr('href','assets/css/bootstrap.min.css');
            $("#app-style").attr('href','assets/css/app.min.css');
            sessionStorage.setItem("is_visited", "light-mode-switch");
        } else if($("#dark-mode-switch").prop("checked") == true && id === "dark-mode-switch"){
            $("#light-mode-switch").prop("checked", false);
            $("#rtl-mode-switch").prop("checked", false);
            $("#bootstrap-style").attr('href','assets/css/bootstrap-dark.min.css');
            $("#app-style").attr('href','assets/css/app-dark.min.css');
            sessionStorage.setItem("is_visited", "dark-mode-switch");
        } else if($("#rtl-mode-switch").prop("checked") == true && id === "rtl-mode-switch"){
            $("#light-mode-switch").prop("checked", false);
            $("#dark-mode-switch").prop("checked", false);
            $("#bootstrap-style").attr('href','assets/css/bootstrap.min.css');
            $("#app-style").attr('href','assets/css/app-rtl.min.css');
            sessionStorage.setItem("is_visited", "rtl-mode-switch");
        }
    }

    function init() {
        initMetisMenu();
        initLeftMenuCollapse();
        initActiveMenu();
        initMenuItem();
        initFullScreen();
        //initRightSidebar();
        initDropdownMenu();
        initComponents();
        initSettings();
        initPreloader();
        Waves.init();
    }
    
/*
    $('#datatable').DataTable({
		  "pageLength": 50,
      "fnDrawCallback": function (oSettings) {
        //$(".best_in_place").best_in_place();
    	}
		});
*/	
		
		//hide flash message
		//$(".alert-notifications").delay(2000).slideUp(500, function(){
          $(".alert-notifications").delay(5000).fadeOut(600);
      //});
    //});
    
    $(".select2").select2();

    //Check all
    $(".select-all_options").click(function(){
        $(this).siblings('ul').find('input:checkbox').not(this).prop('checked', this.checked);
    });
    
    //AJAX get all models based on project
    $(".main-content").on('change', '.project_select', function(e){
        //console.log("it moved");
        
        $.ajax({
          url: '/admin/list_models',
          type: 'GET',
          dataType: 'script',
            data: {
                project_id: $(".project_select option:selected").val()
            },
          error: function() {
              console.log("ERROR");
          },
          success: function(data) {
              //console.log(data);
          }
        });
    });
    
	//FLOOR PLAN OPTION SEARCH
    $("#selection-search").on("keyup", function() {
        var value = this.value.toLowerCase().trim();
        $(".draggable-list .card").show().filter(function() {
          return $(this).find('h5').text().toLowerCase().trim().indexOf(value) == -1;
        }).hide();
    });
		    
    //CHECK CONDITIONS ON SELECTIONS
    $(".main-content").on('change', '.selection_radio', function(e){
	    var other_option_ids = $(this).data("and");
	    var other_option_if_ids = $(this).data("if");
	    var radio = ".model_room_option_" + other_option_ids.toString().split(',')[0];
	   
      
      //check if there are relationships with the selected
	    if(other_option_ids == ""){
		    //DO NOTHING
	    }else{

		    //display modal
			  //$('.modal-body ul').empty();
			  $.ajax({
	        url: '/list_model_room_options',
	        type: 'GET',
	        dataType: 'script',
	          data: {
	              model_room_option_ids: other_option_ids.toString().split(',')
	          },
	        error: function() {
	            console.log("ERROR");
	        },
	        success: function(data) {
	            console.log(data);
	            if(other_option_ids !== ''){
	            	//$("#selection_modal").modal();
	            }
	        }
	      });
      
		    console.log("272 other_option_ids...");
		    console.dir(other_option_ids);
		    
		    
		    
		    if(other_option_ids !== ''){
			    $.each(other_option_ids.toString().split(", ") , function(index, val) { 
					  radio = ".model_room_option_" + val;
					  $(radio).prop("checked", true);
					  //console.log(index, val);
					  //console.log("radio: " + radio);
					});
		    };
		    
			};
				
    });
    
    //progress success modal
    //window.openSuccessModal = $("#success_modal").modal();
    
    //MORE INFO ICON
    $(".more-info").on("click", function(e){
	    e.preventDefault();
	    var option_id = $(this).data("option");
	    var model_room_option_id = $(this).data("model_room_option");
	    
	    $.ajax({
        url: '/options/' + option_id,
        type: 'GET',
        dataType: 'script',
          data: {
              model_room_option_id: model_room_option_id
          },
        error: function() {
            console.log("ERROR");
        },
        success: function(data) {
            //console.log(data);
            //$("#info_modal").modal();
        }
      });
	    
    });
    
    //Faux submit button on the panoramas
    $(".btn-save-selection-trigger").on("click", function(e){
	    e.preventDefault();
	    $(".btn-save-selection").trigger("click");
    });
    
    //hover over structural tr
	$(".table-structural-options tr").mouseenter(function() {
		    var index = $(this).data("index")
			var selector = "#data-index-" + index;
			$(selector).addClass("active");
	}).mouseleave(function() {
	    var index = $(this).data("index")
			var selector = "#data-index-" + index;
	    $(selector).removeClass("active");
	});
    
    //onboarding
    $('.onboarding-slick').slick({
		  dots: true,
		  infinite: false,
		  speed: 500
		});
    
    // interestRate: eg. 6.2% should be passed as 0.062
		function getMortgagePayment(startingLoanAmount, totalPayments, interestRate)
		{
		    let interestRatePerMonth = interestRate / 12;
		    return startingLoanAmount * interestRatePerMonth * (Math.pow(1 + interestRatePerMonth, totalPayments)) / (Math.pow(1 + interestRatePerMonth, totalPayments) - 1);
		}
		
		window.getMortgagePayment = getMortgagePayment;

    
    
    // how many decimal places allows
/*
		var decimal_places = 2;
		var decimal_factor = decimal_places === 0 ? 1 : Math.pow(10, decimal_places);
		
		var starting_monthly_payment = $('#monthly_payment').data("monthly");
		console.log(starting_monthly_payment);
		starting_monthly_payment = parseFloat(starting_monthly_payment);
		$('#monthly_payment')
		  .animateNumber(
		    {
		      number: starting_monthly_payment * decimal_factor,
		
		      numberStep: function(now, tween) {
		        var floored_number = Math.floor(now) / decimal_factor,
		            target = $(tween.elem);
		
		        var formatted = floored_number.toFixed(decimal_places).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
						$(tween.elem).text('$' + formatted);
		      }
		    },
		    600
		  );
*/
    
    
    
/*
    //CHECK CONDITIONS ON SELECTIONS
    $(".main-content").on('change', '.selection_radio', function(e){
	    console.log("change RADIO");
	    var other_option_ids = $(this).data("and");
	    var option_variants = $(this).data("variants");
	    var radio = ".model_room_option_" + other_option_ids.toString().split(',')[0];
	    
	    console.log("other_option_ids: " + other_option_ids);
	    console.log("radio: " + radio);
	    
	    //check if there are relationships with the selected
	    if(other_option_ids == ""){
		    //DO NOTHING
	    }else{

		    //display modal
			  $('.modal-body ul').empty();
		    $("#selection_modal").modal();
		    
		    
		    $.each(other_option_ids.toString().split(", ") , function(index, val) { 
				  radio = ".model_room_option_" + val;
				  $(radio).prop("checked", true);
				  //console.log(index, val);
				  //console.log("radio: " + radio);
				  
				});
				
				$.each(option_variants.toString().split(", ") , function(index, val) { 

				  //list the options in the modal
				  var radio_category = val.split("|")[0]
				  var radio_name = val.split(":")[0]
				  var id_index = "input_" + index.toString();
				  $('.modal-body ul').append('<li><div class="custom-control custom-radio mb-2 mr-sm-3"><input id="' + id_index + '" type="radio" class="custom-control-input" name="' + radio_name + '"><label class="custom-control-label" for="' + id_index + '">' + val + '</label></div></li>');
				  
				  
				});
		    
	    }
	    
    });
*/

    //Shareable link
        
    $(document).on('click', '#shareableLink', function() {
        var link = $(this).data("link");
        navigator.clipboard.writeText(link);
        $("#shareableTooltip").fadeIn();
        setTimeout( function() {
            $("#shareableTooltip").fadeOut();
        }, 2000);
    })

    //Login tabs

    $('.password-tab-btn').on('click', function() {
        $('#email-login').hide();
        $('#password-login').show();
    })

    $('.email-tab-btn').on('click', function() {
        $('#password-login').hide();
        $('#email-login').show();
    })

    init();

})(jQuery)